import company from '@/utils/company'
import '@/vab'
import axios from 'axios'
import echarts from 'echarts'
import Vue from 'vue'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import App from './App'
import i18n from './i18n'
import './registerServiceWorker'
import router from './router'
import store from './store'
Vue.use(VXETable)
// import UmyUi from 'umy-ui'
// import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
// Vue.use(UmyUi);
import loadmore from './utils/loadmore'
Vue.directive(loadmore.name, loadmore.componentUpdated)
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
// import { baseURL } from './config'
// import { isExternal } from '@/utils/validate'

// if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
//   const { mockXHR } = require('@/utils/static')
//   mockXHR()
// }
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.prototype.company = company
new Vue({
  el: '#app',
  i18n,
  store,
  axios,
  router,
  render: (h) => h(App),
})
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem('appVersion')
if (VUE_APP_VERSION != vers) {
  localStorage.clear()
  window.localStorage.setItem('appVersion', VUE_APP_VERSION)
  location.reload()
}
