import request from '@/utils/request'
let resquest = "/api/"

//用户列表
export function userList(data) {
  return request({
    url: `${resquest}user/list`,
    method: 'post',
    data
  })
}

//封禁用户
export function userDelete(data) {
  return request({
    url: `${resquest}user/delete`,
    method: 'post',
    data
  })
}

//解除封禁用户
export function userCancelDelete(data) {
  return request({
    url: `${resquest}user/cancelDelete`,
    method: 'post',
    data
  })
}

//禁言用户
export function userMute(data) {
  return request({
    url: `${resquest}user/mute`,
    method: 'post',
    data
  })
}

//解除禁言用户
export function userCancelMute(data) {
  return request({
    url: `${resquest}user/cancelMute`,
    method: 'post',
    data
  })
}

//获取用户信息
export function userGet(params) {
  return request({
    url: `${resquest}user/get`,
    method: 'get',
    params
  })
}

//获取用户信息
export function userGetMark(params) {
  return request({
    url: `${resquest}user/get/mark`,
    method: 'get',
    params
  })
}

//获取用户信息
export function userUpdateBirthday(params) {
  return request({
    url: `${resquest}user/update/birthday`,
    method: 'get',
    params
  })
}

//身份认证列表
export function userAuthList(data) {
  return request({
    url: `${resquest}user/auth/list`,
    method: 'post',
    data
  })
}

//常用语
export function baseCommonWordList(params) {
  return request({
    url: `${resquest}base/commonWord/list`,
    method: 'get',
    params
  })
}

//审核用户认证信息
export function userCheckMyAuth(data) {
  return request({
    url: `${resquest}user/check/myAuth`,
    method: 'post',
    data
  })
}

//黑名单用户列表
export function userDeleteUserList(data) {
  return request({
    url: `${resquest}user/deleteUser/list`,
    method: 'post',
    data
  })
}

//禁言用户列表
export function userMuteList(data) {
  return request({
    url: `${resquest}user/mute/list`,
    method: 'post',
    data
  })
}

//修改用户密码
export function adminUpdate(data) {
  return request({
    url: `${resquest}auth/user/update/password`,
    method: 'post',
    data
  })
}
