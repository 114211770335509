import { loginRSA } from '@/config'
import { encryptedData } from '@/utils/encrypt'
import request from '@/utils/request'
let user = '/api/'
let reques = '/vab-mock-server/'
export async function login(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: `${user}baseSystem/login`,
    method: 'post',
    data,
  })
}

export async function socialLogin(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: `${user}base/userInfo`,
    method: 'get',
  })
}

export function logout() {
  return request({
    url: `${user}/logout`,
    method: 'get',
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}

export function findAll() {
  return request({
    url: `${user}baseSystem/getSystemCompanyList`,
    method: 'get',
  })
}
