<template>
  <div class="dialog-drag-container">
    <el-dialog
      v-drag
      :before-close="handleClose"
      :modal="modal"
      title="图片预览"
      :visible.sync="dialogVisible"
      width="300px"
    >
      <el-image :preview-src-list="srcList" :src="url" style="height: 250px" />
      <!-- <img :src="url" alt="" style="width:200px;" /> -->
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogDrag',
    data() {
      return {
        fits: ['fill'],
        url: '',
        dialogVisible: false,
        srcList: [],
        modal: false,
      }
    },
    methods: {
      showEdit(row, modal = true) {
        this.srcList = []
        this.dialogVisible = true
        this.url = row.pictureUrl
        this.srcList.push(row.pictureUrl)
        this.modal = modal
      },
      handleClose() {
        this.dialogVisible = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      padding: 0px !important;
    }
  }
</style>
