<template>
  <el-dropdown @command="handleCommand" @visible-change="handleVisibleChange">
    <span class="avatar-dropdown">
      <el-avatar class="user-avatar" :src="avatar" />
      <div class="user-name">
        <span class="hidden-xs-only">{{ username }}</span>
        <vab-icon
          class="vab-dropdown"
          :class="{ 'vab-dropdown-active': active }"
          icon="arrow-down-s-line"
        />
      </div>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <!-- <el-dropdown-item command="personalCenter">
          <vab-icon icon="user-line" />
          {{ translateTitle('个人中心') }}
        </el-dropdown-item> -->
        <!-- <el-dropdown-item command="updatePassword">
          <vab-icon icon="lock-2-line" />
          {{ translateTitle('修改密码') }}
        </el-dropdown-item> -->
        <el-dropdown-item command="logout">
          <vab-icon icon="logout-circle-r-line" />
          {{ translateTitle('退出登录') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
    <table-edit ref="edit" :logout="logout" />
  </el-dropdown>
</template>

<script>
import { translateTitle } from '@/utils/i18n'
import { mapActions, mapGetters } from 'vuex'
import { toLoginRoute } from '@/utils/routes'
import TableEdit from './components/TableEdit'
export default {
  name: 'VabAvatar',
  components: {
    TableEdit,
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/avatar',
      username: 'user/username',
    }),
  },
  mounted() {
    this.needUpdatePw()
  },
  methods: {
    translateTitle,
    ...mapActions({
      _logout: 'user/resetAll',
    }),
    handleCommand(command) {
      switch (command) {
        case 'logout':
          this.logout()
          break
        case 'personalCenter':
          this.personalCenter()
          break
        case 'updatePassword':
          this.updatePassword()
          break
      }
    },
    handleVisibleChange(val) {
      this.active = val
    },
    personalCenter() {
      this.$router.push('/setting/personalCenter')
    },
    async logout() {
      await this._logout()
      await this.$router.push(toLoginRoute(this.$route.path))
    },
    needUpdatePw() {
      
      // const needUpdatePw = localStorage.getItem('needUpdatePw')
      // if (needUpdatePw == 'true') {
      //   this.updatePassword()
      //   localStorage.removeItem('needUpdatePw')
      // }
    },
    updatePassword() {
      // this.$refs['edit'].showEdit()
      window.open('http://basesys.woyoalliance.com:88/#/login','_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-dropdown {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  .user-avatar {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 50%;
  }

  .user-name {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    height: 40px;
    margin-left: 6px;
    line-height: 40px;
    cursor: pointer;

    [class*='ri-'] {
      margin-left: 0 !important;
    }
  }
}
</style>
