var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "logo-container",
      class:
        ((_obj = {}), (_obj["logo-container-" + _vm.theme.layout] = true), _obj)
    },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("span", { staticClass: "logo" }, [
          _c("img", {
            staticStyle: { width: "100px" },
            attrs: { alt: "", src: require("@/assets/logo.png") }
          })
        ]),
        _c(
          "span",
          {
            staticClass: "title",
            class: { "hidden-xs-only": _vm.theme.layout === "horizontal" }
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }