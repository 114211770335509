var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu-item",
    {
      attrs: {
        index: _vm.itemOrMenu.path,
        disabled:
          _vm.itemOrMenu.name == "ProductQuery" ||
          _vm.itemOrMenu.name == "BulkProducts" ||
          _vm.itemOrMenu.name == "TrailerProducts"
      },
      on: { click: _vm.handleLink }
    },
    [
      _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.icon
        ? _c("vab-icon", {
            attrs: {
              icon: _vm.itemOrMenu.meta.icon,
              "is-custom-svg": _vm.itemOrMenu.meta.isCustomSvg,
              title: _vm.translateTitle(_vm.itemOrMenu.meta.title)
            }
          })
        : _vm._e(),
      _c(
        "span",
        { attrs: { title: _vm.translateTitle(_vm.itemOrMenu.meta.title) } },
        [
          _vm._v(
            " " + _vm._s(_vm.translateTitle(_vm.itemOrMenu.meta.title)) + " "
          )
        ]
      ),
      _vm.itemOrMenu.meta && _vm.itemOrMenu.construction == 1
        ? _c("el-tag", { attrs: { effect: "dark", type: "danger" } }, [
            _vm._v(" 建设中 " + _vm._s(_vm.itemOrMenu.meta.badge) + " ")
          ])
        : _vm._e(),
      _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.dot
        ? _c("span", { staticClass: "vab-dot vab-dot-error" }, [_c("span")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }