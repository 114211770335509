<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
.el-tooltip__popper {
  max-width: 20%;
}

.el-tabs__header{
  margin: 0px !important;
}

// 单元格背景颜色值
.cell-bg-1{
  background-color: #eaf4fe;
}
.cell-bg-2 {
  background-color: #f1f3f4;
}
</style>
