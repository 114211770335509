<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    append-to-body
  >
    <el-form ref="form" label-width="90px" :model="form" :rules="rules">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input
          v-model.trim="form.oldPassword"
          show-password
          placeholder="请输入原密码"
          clearable
        />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          v-model.trim="form.newPassword"
          show-password
          placeholder="请输入新密码"
          clearable
        />
      </el-form-item>
      <el-form-item label="确认新密码" prop="Password">
        <el-input
          v-model.trim="form.Password"
          show-password
          placeholder="请再次输入新密码"
          clearable
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { adminUpdate } from '@/api/userList'
import { isPassword } from '@/utils/validate'
import { translateTitle } from '@/utils/i18n'
export default {
  name: 'TableEdit',
  props: {
    logout: Function,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!isPassword(value))
        callback(new Error(this.translateTitle('密码不能少于6位')))
      else callback()
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        Password: '',
        id: localStorage.getItem('id'),
      },
      rules: {
        oldPassword: [
          { required: true, trigger: 'blur', message: '请输入原密码' },
        ],
        Password: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        newPassword: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
          },
        ],
      },
      title: '修改密码',
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    translateTitle,
    showEdit() {
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const { msg } = await adminUpdate(this.form)
          this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          this.$emit('fetch-data')
          this.close()
          this.$alert('请重新登录！', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: (action) => {
              this.logout()
            },
          })
        }
      })
    },
  },
}
</script>
